import React from 'react'
import './ContactsContent.css'

export default function ContactsContent() {
    return (
        <div className='contacts-wrapper'>
            <p className='contacts'>
                twitter: <a href='https://twitter.com/indianmusic' className='contacts-link' target="_blank" rel="noopener noreferrer">@indianmusic</a>
            </p>
            <p className='contacts'>
                tiktok: <a href='https://www.tiktok.com/@shadja?' className='contacts-link' target="_blank" rel="noopener noreferrer">@shadja</a>
            </p>
            <p className='contacts'>
                google scholar: <a href='https://scholar.google.com/citations?user=7nZ0tnEAAAAJ' className='contacts-link' target="_blank" rel="noopener noreferrer">Parag Chordia</a>
            </p>
        </div>
    )
}
