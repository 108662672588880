import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import MenuList from './Components/MenuList/MenuList';
import MainPage from './Pages/MainPage/MainPage';
import ArticlePage from './Pages/ArticlePage/ArticlePage.jsx'
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage'
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-wrapper">
        <MenuList />
        <Switch>
          <Route exact path='/'>
            <MainPage />
          </Route>
          <Route exact path='/about'>
            <ArticlePage about={true} />
          </Route>
          <Route exact path='/links'>
            <ArticlePage links={true} />
          </Route>
          <Route path='/:incorrect'>
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;