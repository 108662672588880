import React, { useEffect, useState, useRef } from 'react';
import Slider from "react-slick";
import SliderArrow from '../SliderArrow/SliderArrow';
import AWS from 'aws-sdk';
import { videoBucket } from '../../constants';
import './VideoBlock.css';

export default function VideoBlock() {
    const [videos, setVideos] = useState([]);
    const slider = useRef(null);

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AK,
        secretAccessKey: process.env.REACT_APP_SK,
        region: process.env.REACT_APP_RG
    });

    useEffect(() => {
        const s3 = new AWS.S3();

        const params = {
            Bucket: 'paragchordia.com',
            Prefix: 'videos/'
        }

        s3.listObjectsV2(params, (err, data) => {
            if (err) {
                console.error(err);
            } else {
                const videosData = data.Contents.sort((i1, i2) => new Date(i1.LastModified).getTime() - new Date(i2.LastModified).getTime());
                setVideos(videosData);
            }
        });

        setTimeout(() => {
            [...document.getElementsByTagName('video')].forEach(video => {
                video.addEventListener('ended', () => {
                    slider.current.slickNext();
                })
            })
        }, 2000);

    }, []);

    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        draggable: true,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SliderArrow />,
        nextArrow: <SliderArrow />,
        beforeChange: () => {
            [...document.getElementsByClassName('video')].forEach(video => {
                video.muted = true;
                video.currentTime = 0;
                video.play();
            })
        }
    }

    return (
        <div className='video-wrapper'>
            <Slider {...settings} ref={slider}>
                {
                    videos.map(video => {
                        return (
                            <video
                            key={video.Key}
                            className='video'
                            controls='controls'
                            playsInline
                            autoPlay
                            muted
                            >
                                <source
                                    src={`${videoBucket}/${video.Key}`}
                                />
                            </video>
                        )
                    })
                }
            </Slider>
        </div>
    )
}