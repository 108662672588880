import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css'

export default function NotFoundPage () {
    return (
        <div className='not-found-wrapper'>
            <h1>404 - Page Not Found :)</h1>
            <Link to="/">
                Back to Home Page
            </Link>
        </div>
    )
}