import React from 'react'
import './PageWrapper.css'

export default function PageWrapper({ children }) {
    return (
        <div className='page-wrapper'>
            {children}
        </div>
    )
}
