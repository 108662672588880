import React from 'react'
import sliderArrow from './SliderArrow.svg'

export default function SliderArrow({ className, style, onClick }) {
    return (
        <div className='slider-arrow' onClick={onClick}>
            <img
                src={sliderArrow}
                style={{ ...style }}
                className={className}
                alt="arrow"
            />
        </div>
    )
}
