import React, { useRef } from 'react'
import './HamburgerButton.css'

export default function HamburgerButton() {
    const hamburgerButton = useRef(null);
    const toggleButtonState = () => {
        hamburgerButton.current.classList.toggle("change");
        document.getElementsByClassName('menu-wrapper')[0].classList.toggle('hidden');
    }

    return (
        <div className='hamburger-button' onClick={toggleButtonState} ref={hamburgerButton}>
            <div>
                <div className='hamburger-button_bar first-bar'></div>
                <div className='hamburger-button_bar second-bar'></div>
                <div className='hamburger-button_bar third-bar'></div>
            </div>
        </div>
    )
}
