import React from 'react'
import { Link } from "react-router-dom";
import './MenuList.css'

export default function Menu() {
    const handleLinkClick = () => {
        document.getElementsByClassName('hamburger-button')[0].classList.toggle("change");
        document.getElementsByClassName('menu-wrapper')[0].classList.remove('hidden');
    }

    return (
        <div className='menu-wrapper'>
            <nav>
                <ul>
                    <li>
                        <Link to='/' className='navigation-link' onClick={handleLinkClick}>home</Link>
                    </li>
                    <li>
                        <Link to='/about' className='navigation-link' onClick={handleLinkClick}>about</Link>
                    </li>
                    <li>
                        <Link to='/links' className='navigation-link' onClick={handleLinkClick}>links</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
