import React from 'react'
import ContactsContent from '../../Components/ContactsContent/ContactsContent'
import HamburgerButton from '../../Components/HamburgerButton/HamburgerButton'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import './ArticlePage.css'

export default function ArticlePage({ about, links }) {
    return (
        <>
            <PageWrapper>
                <HamburgerButton />
                <div className='page-paragraph'>
                    {
                    about
                    ?
                    <p className='about'>
                        Parag Chordia is a musician and entrepreneur living in the Bay Area. He has
                        built several companies at the intersection of art and technology with his
                        wife <a href='https://twitter.com/prernagupta' className='about-link' target="_blank" rel="noopener noreferrer">Prerna Gupta</a> — Khush (AI music tech)
                        acquired by Smule, Hooked (chat fiction) and mysterious (stealth).
                        Previously he founded the NSF-funded Music Intelligence Lab at Georgia Tech
                        where he was a professor. He received his PhD in ML+Music from Stanford and
                        his BS in <span className='no-wrap'>Applied Mathematics from Yale</span>.
                    </p>
                    :
                    <ContactsContent />
                    }
                </div>
            </PageWrapper>
        </>
    )
}