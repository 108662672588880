import React from 'react'
import HamburgerButton from '../../Components/HamburgerButton/HamburgerButton'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import VideoBlock from '../../Components/VideoBlock/VideoBlock'

export default function MainPage() {
    return (
        <>
            <PageWrapper>
                <HamburgerButton />
                <VideoBlock />
            </PageWrapper>
        </>
    )
}
